export const errorsData = [
  {
    code: 'F0909',
    time: '2020-06-11 22:09:59',
    description: "default Keyring's certificate is invalid, reason: expired.",
    severity: 'Critical',
  },
  {
    code: 'NOD-0008',
    time: '2020-07-21 14:53:16',
    description:
      'Triggered when one or more configured NTP servers on controller VM cannot be reached.',
    severity: 'Warning',
  },
  {
    code: 'NOD-0002',
    time: '2020-06-30 21:51:00',
    description: 'Triggered when a node upgrade to a version fails.',
    severity: 'Warning',
  },
  {
    code: 'CLU-0016',
    time: '2020-08-07 17:24:09',
    description:
      'Triggered when cluster access policy compliance has degraded. This alarm is reset when the policy compliance is met.',
    severity: 'Warning',
  },
  {
    code: 'UCS-F1932',
    time: '2020-09-05 02:20:00',
    description: 'Chassis enclosure for server 2 is open',
    severity: 'Info',
  },
];

export const errorAggregation = {
  CriticalAlarmsCount: errorsData.filter((d) => d.severity === 'Critical')
    .length,
  InfoAlarmsCount: errorsData.filter((d) => d.severity === 'Info').length,
  WarningAlarmsCount: errorsData.filter((d) => d.severity === 'Warning').length,
};
