import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';

// @ts-ignore
export interface AggregationCardProps {
  title: string;
  aggregation: number | string;
  color?: string;
}

export const AggregationCard: FunctionComponent<AggregationCardProps> = (
  props
) => {
  return (
    <Paper>
      <Grid container alignItems={'center'} direction={'column'}>
        <Grid item>
          <Typography
            variant={'subtitle1'}
            style={{ fontWeight: 600, color: '#858585' }}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item style={{ padding: 20 }}>
          <Typography
            style={{
              fontWeight: 800,
              color: props.color ? props.color : 'black',
            }}
          >
            {props.aggregation}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AggregationCard;
